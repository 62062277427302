//
// nav
//

.nav {
  .nav-link {
    position: relative;
    color: inherit;
  }
}

// tabs
.nav-tabs {
  position: relative;
  border: 0;

  .nav-item {
    margin: 0 rem(16) rem(8) 0;
  }

  .nav-link {
    transition: all 0.3s ease-in-out;
    display: inline-block;
    padding: rem(8) 0;
    border-width: 0 0 $border-width 0;
    border-radius: 0;
    border-color: transparent;
    text-align: left;
    background: transparent;
    color: $gray-600;

    &:hover {
      color: $black;
      border-color: transparent;
    }

    &.active {
      color: $black;
      border-color: $black;
      background: transparent;
    }
  }
}

.nav-item {
  .nav-label {
    display: flex;
    color: $black;
  }

  &:not(:first-child) {
    .nav-label {
      margin-top: rem(12);
    }
  }
}

// pills
.nav-pills {
  display: inline-flex;
  border: $border-width solid $border-color;

  .nav-link {
    padding: rem(12) rem(24);
    color: $gray-800;
    background: transparent;
  }

  .nav-link.active {
    color: $black;
    background: $border-color;
  }
}

// minimal
.nav-minimal {
  display: flex;
  flex-direction: column;

  .nav-item:not(:first-child) {
    margin-top: rem(16);
  }

  .nav-link {
    display: inline-block;
    position: relative;
    padding: 0 rem(32) 0 0;

    &[aria-expanded]::before {
      transition: transform 0.2s;
      position: absolute;
      top: 50%;
      right: 0;
      content: "\f282";
      width: rem(24);
      margin-top: rem(-12);
      text-align: center;
      line-height: rem(24);
      font-family: bootstrap-icons !important;
      font-size: rem(18);
      color: $gray-600;
    }

    &[aria-expanded="true"]::before {
      transform: rotate(180deg);
    }

    &[class*="active"] {
      color: $primary !important;
    }

    &:hover {
      color: $primary !important;
    }
  }

  // nested
  .nav-minimal {
    margin-top: rem(8);
    margin-bottom: rem(8);

    .nav-item {
      margin-top: rem(8);
    }

    .nav-link {
      color: $secondary;
    }
  }
}
